.order-details-container {
  padding: 30px 30px 50px 30px;
  margin-top: 30px;
  border-radius: 15px;
  font-family: "Inter", sans-serif;
  background: #fbfbfb;
}

.order-detail-collapse-container {
  padding: 30px;
  font-family: "Inter", sans-serif;
}

.customer-flex {
  display: flex;
  gap: 100px;
}

.status-dropdown {
  margin-top: 30px;
}

.status-dropdown > h5 {
  margin-bottom: 20px;
  color: #6a6a6a;
  font-family: "Inter", sans-serif;
}

.status-dropdown .ant-dropdown-trigger {
  background: #e0e0e0;
  color: #aaaaaa !important;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 40px !important;
}

.status-assign-flex {
  display: flex;
  align-items: center;
  gap: 50px;
}

.assign-rider-details > h5 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
}

.error {
  color: red;
}

.assign-rider-button {
  margin-top: 50px;
}

.order-delivery-container hr,
.order-details-container hr {
  margin-top: 10px;
}

.ant-dropdown-trigger span svg {
  margin-left: 15px !important;
  color: #aaaaaa !important;
}

.customer-flex > h5 {
  color: #6a6a6a;
  font-size: 14px;
  margin-top: 20px;
}

.order-delivery-container {
  margin-top: 40px;
}

.customer-flex > h5:nth-child(2) {
  color: #5a6474;
}

.order-details-flex {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.text-image-flex {
  display: flex;
  gap: 30px;
}

.delivery-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.total-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.order-image {
  width: 68px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
}

.order-text .category {
  color: #f8bb15;
}

.order-amount-number {
  display: flex;
  gap: 30px;
}
