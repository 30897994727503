.email-verify-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.email-verification-input {
  width: 60px !important;
  height: 60px !important;
  font-family: "Inter", sans-serif !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  background: #fff !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  text-align: center !important;
  margin: 15px !important;
}

.email-verification-input:focus {
  outline: none !important;
}
