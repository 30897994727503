.admin-login-container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-login-card {
  padding: 50px;
  width: 540px;
  height: 600px;
  margin-top: 10%;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logo {
  margin: 20px auto;
}

.admin-login-card > h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
}

.admin-login-card > p {
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.admin-login-form > label {
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: "Inter", sans-serif;
}

.admin-login-form > .admin-input {
  padding: 15px;
  margin-bottom: 30px;
  font-family: "Inter", sans-serif;
  width: 95%;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.admin-login-form > .admin-input:focus {
  outline: none;
}

.radio-input-forgot-password {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}

.radio-input-forgot-password > a {
  color: #000;
  text-decoration: underline !important;
  font-family: "Inter", sans-serif;
}

.radio-input-forgot-password > label {
  font-family: "Inter", sans-serif;
}

.radio-input-forgot-password > label > input {
  margin-right: 10px;
}

.admin-login-form > button {
  display: block;
  margin: auto;
  width: 40%;
  padding: 15px;
  margin-top: 10px;
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  background: #101828;
  border-radius: 14px;
}

.ant-spin-dot-item {
  background-color: white !important;
}

.ant-notification-notice-success {
  background: #34a751 !important;
  height: 65px !important;
  border-radius: 4px !important;
}

.ant-notification-notice-error {
  background: #f2667e !important;
  height: 75px !important;
  border-radius: 4px !important;
}

.ant-notification-notice-message {
  color: #fff !important;
  font-size: 13px !important;
  font-family: "Inter", sans-serif !important;
}

.ant-notification-notice-icon-success.anticon {
  color: #fff !important;
}

.ant-notification-notice-icon-error.anticon {
  color: #ffd9df !important;
}

.ant-notification-notice-close {
  color: #fff !important;
}

.ant-notification-notice-description {
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
}

.admin-login-form > button:hover {
  cursor: not-allowed;
}

.admin-login-form > button.active:hover {
  cursor: pointer;
}

.error-message-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}

.admin-login-form .error {
  color: red;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
